.main-content {
	display: flex;
	max-width: 100vw;
	overflow: hidden;
}

.menu-city-council svg {
	width: 16px;
	height: 16px;
}
.menu-city-council .ant-menu-item.ant-menu-item-only-child {
	padding-right: 5px;
}

.main-content .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
	min-width: 3px;
	max-width: 3px;
	background-color: #d9d9d9;
}
