.layout-style {
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fc;
}

.login-title {
  font-size: 38px;
  font-weight: 500;
  font-family: "Roboto";
  line-height: 1.21;
}
.login-text-title {
  display: block;
  margin: 12px 5px 0px 5px;
  font-family: "Roboto";
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
}
.login-input {
  height: 40px;
  padding: 9px 12px 9px 36px;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 8px;
}

.login-icon {
  font-size: 16px;
  color: rgba(20, 20, 20, 1);
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 12px;
  transform: translate(0, -50%);
}
