.content-conteiner.form-general-info {
	padding-left: 0;
	padding-right: 0;
	height: calc(100vh - 244px);
	overflow-y: scroll;
}
.ant-table-fixed {
	table-layout: fixed;
}

.ant-table-tbody > tr > td {
	word-wrap: break-word;
	word-break: break-all;
}

.general-form-box {
	width: 360px;
	margin: 0 auto;
}

.form-title-box {
	width: 360px;
	margin: 0 auto 24px auto;
	position: relative;
	text-align: center;
}
.form-title-box::after {
	content: '';
	width: 100%;
	height: 1px;
	background-color: #f5f5f5;
	position: absolute;
	left: 0;
	top: 50%;
	z-index: 0;
}
.form-title-box h2 {
	padding: 0 5px;
	margin: 0;
	display: inline-block;
	font-size: 16px;
	font-weight: 500;
	position: relative;
	z-index: 5;
	background-color: #ffff;
}

.dragger-photo {
	display: block;
	margin-bottom: 24px;
}
.general-info-form .ant-form-item-label label {
	font-size: 16px !important;
}
.divider-form {
	height: 16px;
	width: 100%;
	background-color: #f8f9fc;
}

.general-info-form .ant-form-item {
	width: 360px;
	margin-left: auto;
	margin-right: auto;
}

.contact-person {
	width: 360px;
	margin: 0 auto;
	display: flex;
	gap: 8px;
	position: relative;
}
.contact-person__item {
	width: calc(100% - 8px);
}
.contact-person__item .ant-form-item {
	width: 100%;
	margin-bottom: 8px;
}
.powers-person {
	margin: 0px 115px 24px 0px;
	display: flex;
	position: relative;
}
.powers-person__item {
	width: 550px;
	padding: 0px 16px;
	box-sizing: border-box;
}
.powers-person__item .ant-form-item {
	width: auto;
}

.general-form-table-agent {
	margin: 0 24px 24px 24px;
}

.general-form-buttons-submit .ant-form-item-control-input-content {
	display: flex;
	justify-content: space-evenly;
}
.general-form-map {
	width: 621px;
	height: 360px;
	margin: 0 auto;
}
.form-item-to-row div {
	flex-direction: row !important;
	align-items: center !important;
}

.form-item-to-row .ant-form-item-label {
	padding: 0;
}
.form-item-to-row .ant-form-item-control {
	width: auto !important;
}
.general-info-form .space-to-row .ant-form-item {
	width: auto;
	margin-bottom: 16px;
}
.space-to-row {
	display: flex;
	align-items: end;
}
.btn-copy-work-time {
	margin-bottom: 16px;
	border: none;
	box-shadow: none;
	color: #00000040;
	background-color: transparent !important;
}
.general-info-form .space-to-row .ant-form-item.item-work-days {
	width: 280px;
}
.item-work-days__checkbox {
	width: 120px;
	margin-right: 15px;
}

.anchor {
	position: absolute;
	top: 4px;
	right: 40px;
	padding-right: 15px;
	background-color: #ffff;
}
.anchor a {
	font-size: 12px;
}
.anchor-events {
	position: absolute;
	background-color: white;
	top: 178px;
	right: 60px;
	padding-right: 15px;
}
.anchor-events a {
	font-size: 12px;
}

.add-contact-person-box {
	display: block;
	margin: 0 auto 24px auto;
	width: 360px;
}
.add-powers-box {
	margin: 0 24px 24px 24px;
}
.btn-add-contact-person {
	height: auto;
	padding: 2px 4px;
	border: none;
	box-shadow: none;
	color: #00000040;
	background-color: transparent !important;
}

.custom-upload-box {
	margin: 0;
}
.custom-upload-box.no-data .ant-upload.ant-upload-select {
	width: 100% !important;
	margin-bottom: 24px !important;
	height: auto !important;
}
.custom-upload-box.no-data.error .ant-upload.ant-upload-select {
	border: 1px dashed #ff4d4f !important;
}
.custom-upload-box .ant-upload.ant-upload-select {
	width: 104px !important;
	height: 104px !important;
	margin-right: 0 !important;
	margin-bottom: 24px !important;
}
.custom-upload-box .ant-upload-list-item-container {
	width: 104px !important;
	height: 104px !important;
	margin-block: 0 24px !important;
	margin-inline: 0 24px !important;
}
.custom-upload-box .ant-upload-list-item-container:nth-child(3n) {
	margin-right: 0 !important;
	margin-bottom: 0 !important;
}

.title-photo-box::before {
	display: inline-block;
	margin-inline-end: 4px;
	color: #ff4d4f;
	font-size: 14px;
	font-family: SimSun, sans-serif;
	line-height: 1;
	content: '*';
}
.title-photo-box span {
	font-style: italic;
	color: #00000040;
}

.adress-home-block {
	display: flex;
	gap: 8px;
	width: 360px;
	margin: 0 auto;
}
