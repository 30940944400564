.str-chat {
	--str-chat__primary-color: #009688;
	--str-chat__active-primary-color: #004d40;
	--str-chat__surface-color: #f5f5f5;
	--str-chat__secondary-surface-color: #fafafa;
	--str-chat__primary-surface-color: #e0f2f1;
	--str-chat__primary-surface-color-low-emphasis: #edf7f7;
	--str-chat__border-radius-circle: 6px;
}
.str-chat.messaging.str-chat-channel.str-chat__channel {
	width: 100%;
	height: calc(100dvh - 48px);
}
.str-chat.messaging.str-chat-channel-list.str-chat__channel-list.str-chat__channel-list-react {
	min-width: 300px;
}

.users-list {
	padding: 12px;
	border-right: 1px solid #009688;

	&-item {
		transition: 0.5s;
		&:hover {
			// transform: scale(1.1);
			cursor: pointer;
			background-color: rgba(181, 180, 180, 0.279);
		}
		border-left: 1px solid #009688;
		background-color: white;
		margin-bottom: 12px;
		font-size: 16px;
		word-wrap: break-word;
		border-radius: 0 8px 8px 0;
		padding-inline: 8px !important;
		color: white;
		min-width: 300px;
		width: 100%;

		&.active {
			border-left: 2px solid #009688;
			background-color: #00968728;
		}
	}
}
