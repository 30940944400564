.schedule-table {
	display: flex;
	justify-content: center;
}
.schedule-table .schedule-table__day .ant-card-head {
	background-color: rgba(236, 236, 233, 0.529);
}

.schedule-table .schedule-table__day .ant-card-body {
	padding: 12px 4px;
}

/* .schedule-table__day {
	
} */
.schedule-table__title {
	display: block;
}
.schedule-table__title--work-time {
	color: #878782;
	font-size: 14px;
}
.schedule-table__checkbox {
	margin-bottom: 12px;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.schedule-table__checkbox--time {
	padding: 2px 6px;
	color: #ffff;
	border-radius: 20px;
}
.add-schedule-box {
	padding: 24px 24px 12px;
	margin-bottom: 24px;
	border: 1px solid #ecece9;
	border-radius: 6px;
}
.add-schedule-form {
	min-height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: start;
	gap: 6px;
}
