@import url('/src/pages/LoginPage/LoginPage.css');
@import url('/src/components/CommunityDashboard/CommunityDashboard.css');
@import url('/src/components/UserCommunityStatus/UserCommunityStatus.css');
@import url('/src/components/CommunityComponents/StyleCommunity/StyleCommunity.css');
@import url('/src/components/CityCouncilComponents/Settings/EditFormCityCouncilSettings/components/EditFormCityCouncilSettings.css');
@import url('/src/pages/CityCouncilPage/CityCouncil.css');
@import url('/src/components/CityCouncilComponents/CityCouncilStyle/CityCouncilStyle.css');
@import url('/src/components/CityCouncilComponents/Departments/components/tabs/schedule/schedule.css');

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
