.quill-news {
	margin: 0px 24px 24px;
}
.quill-news .ql-editor {
	min-height: 200px;
}

.quill-historical {
	margin: 24px;
}

.quill-historical .ql-editor {
	min-height: 350px;
}

.auto-complete-add-admin {
	width: 100%;
}
.auto-complete-add-admin > .ant-select-clear {
	width: 20px;
	height: 20px;
	font-size: 15px;
	margin-top: -8px;
}

/**** Docs Constructor ****/
.docs-constructor-category-label {
	padding: 4px 12px;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	background-color: #00000005;
}
/* .general-form-doc-constructor {
  background-color: #ffff;
  padding-top: 16px;
} */
/**** Docs Constructor Tabs ****/

.tabs-docs-constructor .ant-tabs-tab,
.tabs-docs-constructor .ant-tabs-nav-add {
	border-radius: 2px 2px 0 0 !important;
}
.tabs-docs-constructor .ant-tabs-nav-wrap {
	background-color: #ffff;
	padding: 0 24px;
	height: 40px;
}
.step-constructor {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0px 24px 24px 24px;
	min-height: calc(100vh - 333px);
	max-width: calc(100vw - 285px);
}

.step-constructor_buttons-submit {
	display: flex;
	gap: 24px;
	background-color: white;
	padding: 24px 0;
	margin-top: 24px;
	justify-content: center;
}
.step-constructor__menu {
	width: 510px;
	background-color: transparent;
	border: none !important;
}
.step-constructor__menu > li > div {
	background-color: #ffff;
}

.step-constructor__content .ant-menu.ant-menu-sub.ant-menu-inline {
	background-color: transparent;
	display: flex;
	flex-wrap: wrap;
}
.step-constructor__content .ant-menu.ant-menu-sub.ant-menu-inline.ant-menu-hidden {
	display: none;
}
.step-constructor__content .ant-menu-item.ant-menu-item-only-child {
	padding: 0 !important;
	text-align: center;
	cursor: default;
	width: calc(100% / 2 - 8px) !important;
	height: 40px;
}
.step-constructor__content {
	display: flex;
	gap: 24px;
}
.step-constructor__item {
	display: flex;
	gap: 10px;
	width: 100%;
	align-items: center;
	border-radius: 8px;
	padding: 8px 24px;
	text-align: center;
	background-color: #ffff;
	height: 50px;
}
.step-constructor__item:hover {
	background-color: rgba(0, 0, 0, 0.06);
}
.step-constructor__dnd {
	width: 100%;
}
.step-constructor__open-menu {
	display: none;
	gap: 8px;
	justify-content: space-between;
	width: 448px;
	margin: 4px;
}
.dragg-area {
	min-height: calc(100vh - 352px);
	border: 2px dashed #d9d9d9;
	border-radius: 8px;
	background-color: rgba(0, 0, 0, 0.02);
}

.upload-area {
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	min-height: 170px;
	border: 2px dashed #d9d9d9;
	border-radius: 8px;
	background-color: rgba(0, 0, 0, 0.02);
}
.dnd-card {
	position: relative;
	width: 100%;
	background-color: #ffff;
	border-radius: 8px;
	padding: 16px 56px 16px 48px;
	margin-bottom: 8px;
}
.dnd-drop-icon {
	font-size: 16px;
	cursor: move;
	position: absolute;
	top: 12px;
	left: 12px;
}
.dnd-delete-icon {
	font-size: 16px;
	color: #ff0000;
	position: absolute;
	top: 12px;
	right: 12px;
}
.dnd-card__card-title {
	margin: 0;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.5;
}
.dnd-card__input-title {
	margin: 16px 0px 4px 0px;
}
/* set transition to none when type="editable-card" */
.ant-tabs-editable > .ant-tabs-nav .ant-tabs-tab {
	transition: none;
}

.modal-delete > .ant-modal-content {
	padding: 28px 40px;
}
.title-photo-constructor span {
	font-style: italic;
	color: #00000040;
}
.general-form-buttons-submit {
	display: flex;
	justify-content: center;
	background-color: #ffff;
	margin: 0px 24px;
	padding: 12px 0px 24px 0px;
}
.content-conteiner.constructor-conteiner {
	margin: 0 24px;
	padding: 12px 0px;
}
.constructor-form {
	padding-bottom: 24px;
	max-width: calc(100vw - 237px);
}
.constructor-autofill {
	position: relative;
}
.constructor-autofill__item > .ant-form-item {
	margin-bottom: 12px;
}
.constructor-block-price {
	display: flex;
	gap: 16px;
}
.constructor-block-price__item {
	width: calc(100% / 2 - 16px);
}

/*** Kanban ***/

.kanban {
	display: flex;
	flex: 1 1 auto;
	/* overflow: auto; */
	max-height: calc(100vh - 205px);
}

.kanban-container {
	display: flex;
	flex-direction: row;
	padding: 10px 0 10px 10px;
	background: rgb(250, 250, 250);
}

.kanban-column {
	display: flex;
	flex-direction: column;
	margin-right: 10px;
	min-width: 278px;
	max-width: 278px;
	border: 1px solid #e5e5e5;
	border-radius: 4px;
	background-color: #eee;
}

.kanban-column-header {
	display: flex;
	align-items: center;
	padding: 10px;
	font-weight: bold;
	font-size: 0.875rem;
	height: 56px;
	font-size: 16px;
	font-weight: 500;
}

.kanban-column-list {
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: auto;
	margin: 0;
	padding: 10px 10px 0;
	list-style: none;
	background-color: #f5f5f5;
}

.kanban-column-footer {
	padding: 10px;
	font-size: 0.875rem;
}

.card {
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #fff;
	margin-bottom: 10px;
	cursor: grab;
}
.card__title {
	padding: 16px 16px 0px 16px;
	display: flex;
	justify-content: space-between;
}
.card__name {
	padding: 16px;
	color: #152235;
	font-size: 16px;
	font-weight: 500;
}
.card__footer {
	border-top: 1px solid #f5f5f5;
	height: 40px;
	width: 100%;
	display: flex;
}
.card__footer-item {
	display: flex;
	align-items: center;
	justify-content: center;
	width: calc(100% / 3);
	position: relative;
}
.card__footer-item:not(:last-child)::after {
	content: '|';
	color: #f5f5f5;
	position: absolute;
	right: 0;
}

.card:hover {
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Custom Scroll */
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	margin: 3px;
	padding: 3px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 3px;
}

::-webkit-scrollbar-thumb {
	background: #aaa;
	border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.content-conteiner.documents-statements {
	padding: 0;
	max-width: calc(100vw - 275px);
	max-height: calc(100vh - 245px);
	/* overflow-y: scroll;
  overflow-x: visible; */
	overflow: auto;
}

.documents-statements-table-cards {
	display: flex;
	gap: 16px;
	align-items: center;
	font-size: 14px;
}
.documents-statements-table-cards.no-active {
	display: none;
}
.documents-statements-table-cards__item {
	display: flex;
	align-items: center;
	cursor: pointer;
}
.documents-statements-table-cards__item.active {
	color: #1677ff;
}

.documents-statements-table-cards__icon {
	font-size: 16px;
	margin-right: 4px;
}
.statements-list-table {
	padding: 4px 12px;
	display: flex;
	align-items: center;
	width: max-content;
	border-radius: 4px;
}
.statements-list-table__circle {
	display: inline-block;
	width: 10px;
	height: 10px;
	border: 2px solid white;
	border-radius: 50%;
	margin-right: 8px;
}

.modal-preview > .ant-modal-content {
	min-width: 550px;
	/* min-height: 75vh; */
	padding: 24px 0px 0px 0px;
}
.preview-step {
	border-top: 1px solid #f5f5f5;
	border-bottom: 1px solid #f5f5f5;
	padding: 12px 24px;
}
.preview-block-title {
	margin: 0 0 12px 0;
	font-size: 14px;
	font-weight: 500;
}
.preview-item-title {
	font-size: 12px;
	margin: 0 0 8px 0;
	color: #8c8c8c;
}
/* .preview-item-text {
	margin: 0;
} */
.preview-item-text {
	margin: 6px 0px;
}
.preview-item-files {
	display: flex;
	align-items: center;
}
.preview-footer {
	padding: 24px;
	display: flex;
	gap: 24px;
	justify-content: center;
	align-items: center;
}
.form-submit-buttons {
	margin-bottom: 12px;
	text-align: center;
}
.tasks-table {
	width: 100%;
	margin: 16px;
}

.tasks-table .ant-spin-container {
	height: calc(100vh - 277px);
}

.staff-items-tabs {
	display: flex;
	align-items: center;
}
.add-staff-btn {
	display: flex;
	align-items: center;
	margin-left: auto;
	height: 40px;
}
.staff-tabs > .ant-tabs-nav {
	margin: 0px 24px 0px auto;
}
.staff-table .ant-spin-container {
	height: calc(100vh - 300px);
}

.staff-conteiner {
	height: calc(100vh - 256px);
	/* max-width: calc(100vw - 270px); */
	overflow: auto;
	padding: 12px;
}
.staff-cards-conteiner {
	display: flex;
	gap: 12px;
	flex-wrap: wrap;
	padding: 12px 0px;
}
@media screen and (min-width: 1440px) {
	.staff-conteiner {
		padding: 24px;
	}
	.staff-cards-conteiner {
		gap: 40px;
		padding: 24px 0px;
	}
}
.staff-card {
	width: calc(100% / 4 - 9px);
	height: min-content;
	cursor: default;
}
.staff-cards__input {
	width: calc(100% / 4 - 9px);
}
@media screen and (min-width: 1440px) {
	.staff-card {
		width: calc(100% / 4 - 30px);
	}
	.staff-cards__input {
		width: calc(100% / 4 - 30px);
	}
}

.staff-cards-conteiner .staff-card .staff-card__avatar {
	width: 96px;
	height: 96px;
	border-radius: 50%;
	margin: 16px auto;
	display: flex;
	align-items: center;
	justify-content: center;
	object-fit: contain;
}
.staff-cards-conteiner .staff-card .ant-card-body {
	padding: 12px 24px;
}
.icons-btn-style {
	font-size: 16px;
	cursor: pointer;
	padding: 8px;
}
.icons-btn-style svg {
	font-size: 16px;
}
.staff-form-container {
	background-color: #ffff;
	padding: 24px 0px;
	margin: 24px;
}
.staff-form-item {
	width: 360px;
	margin: 0 auto 8px;
}
.staff-form-container .staff-form-item label {
	font-size: 16px;
	color: #152235;
	line-height: 1.5;
}
.department-card {
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	margin-bottom: 8px;
}
.department-card__title {
	display: flex;
	justify-content: space-between;
	font-size: 16px;
	font-weight: 500;
	padding: 8px 24px;
	border-bottom: 1px solid #d9d9d9;
}
.department-card__body {
	padding: 24px 16px;
}
.staff-balance-container {
	background-color: #ffff;
	padding: 24px;
	margin: 24px;
}
.staff-requests-container {
	background-color: #ffff;
	padding: 0px 12px 12px;
	margin: 24px;
}
.modal-add-new-person .ant-modal-title {
	text-align: center;
	margin-bottom: 24px;
}
.modal-add-new-person {
	width: 690px !important;
	padding: 24px;
}
.modal-add-new-person .ant-steps-item-content .ant-steps-item-description {
	max-width: none !important;
}

.modal-add-new-person .ant-modal-footer {
	text-align: center;
}
.modal-add-new-person button {
	min-width: 100px;
}
.modal-add-new-person .ant-modal-close {
	min-width: 22px;
}

.auto-complete-add-staff {
	width: 360px;
}
.auto-complete-add-staff > .ant-select-clear {
	width: 20px;
	height: 20px;
	font-size: 15px;
	margin-top: 0px;
}

.staff-add-new-btns {
	display: flex;
	gap: 24px;
	justify-content: center;
	margin-top: 24px;
}
.staff-form-item.staff-form-item__not-disabled .ant-input-affix-wrapper-disabled {
	background-color: #ffff;
	color: rgba(0, 0, 0, 0.88);
}
.staff-form-item__not-disabled .ant-picker-disabled {
	background-color: #fff !important;
	color: rgba(0, 0, 0, 0.88);
}
.staff-form-item__not-disabled .ant-checkbox-disabled {
	background-color: #fff !important;
	color: rgba(0, 0, 0, 0.88);
}
.staff-form-item__not-disabled .ant-select-selector {
	background-color: #fff !important;
	color: rgba(0, 0, 0, 0.88);
}
.staff-form-item.staff-form-item__not-disabled .ant-input-affix-wrapper-disabled input {
	color: rgba(0, 0, 0, 0.88);
}
.staff-form-item.staff-form-item__not-disabled .ant-radio-wrapper-disabled {
	color: rgba(0, 0, 0, 0.88);
}
.staff-form-item.staff-form-item__not-disabled .ant-select-selection-item {
	color: rgba(0, 0, 0, 0.88);
}
.staff-form-item.staff-form-item__not-disabled .ant-checkbox-disabled + span {
	color: rgba(0, 0, 0, 0.88);
}
.staff-form-item.staff-form-item__not-disabled .ant-picker-disabled input {
	color: rgba(0, 0, 0, 0.88);
}

.staff-modal-preview__block {
	border-top: 1px solid #f5f5f5;
	border-bottom: 1px solid #f5f5f5;
	padding: 0 24px;
	font-size: 16px;

	transition: all 250ms;
}
.staff-modal-preview__block:hover {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.staff-modal-preview__title {
	font-size: 14px;
	margin: 12px 0px 0px 0px;
}
.staff-modal-preview__text {
	margin: 0px 0px 12px 0px;
	font-weight: 500;
}
.staff-modal-preview__department-title {
	font-size: 16px;
	font-weight: 500;
	text-decoration: underline;
	text-align: end;
}
.staff-modal-preview__text-no-data {
	font-size: 14px;
	font-weight: 400;
	font-style: oblique;
	color: #bbbaba;
}

.staff-form-container .ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
	margin: 0;
}

/*** Calendar ***/
.content-conteiner.calendar {
	width: calc(100vw - 275px);
	height: calc(100vh - 199px);
	padding: 0 12px 12px;
	overflow: auto;
}
.city-council-calendar .events {
	padding: 0;
	margin: 0;
}
.city-council-calendar .events li {
	list-style: none;
	cursor: pointer;
}
.city-council-calendar .ant-select.ant-picker-calendar-month-select {
	min-width: 105px;
}
.city-council-calendar .events .calendar-item-status {
	list-style: none;
	font-size: 12px;
	display: inline-block;
	margin: 2px 0px;
	padding: 3px;
	border-radius: 4px;
	background-color: #daf3ec;
}

.ant-picker-calendar.city-council-calendar .ant-picker-cell-inner.ant-picker-calendar-date {
	cursor: default;
}
.city-council-calendar .notes-month {
	cursor: default;
	list-style: none;
	padding: 6px;
	border-radius: 4px;
	font-weight: 500;
}
.calendar-steps.ant-steps-horizontal {
	display: none;
}
.calendar-madal-add-new-event {
	/* width: 360px !important; */
	padding: 24px;
}
.calendar-madal-add-new-event .ant-modal-title {
	text-align: center;
	margin-bottom: 24px;
}
.calendar-madal-add-new-event button {
	min-width: 100px;
}
.calendar-modal-title-add-event {
	text-align: left;
	border-bottom: 2px solid rgba(5, 5, 5, 0.06);
	font-size: 14px;
}
.calendar-modal-title-add-event span {
	color: #1677ff;
}
.calendar-madal-add-new-event .ant-modal-close {
	min-width: 22px;
}

.content-conteiner.schedule {
	padding: 0 12px 12px;
}
.schedule-table__title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 24px;
}

.schedule-table .ant-table-cell.staff-column-days,
.schedule-table .ant-table-cell.staff-column-full-name {
	padding: 0;
}

.ant-table-wrapper.schedule .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
	background: #e6f4ff;
}

.schedule-table.schedule .ant-table-cell {
	border-left: 1px solid white;
	border-right: 1px solid white;
	padding: 0;
}

.schedule-table.schedule .ant-table-cell.day-weekend {
	background-image: url(../../../../public/assets/img/BackgImg.png);
	color: #818080ba;
}
.schedule-table.schedule .ant-table-cell.day-holiday {
	/* background-color: #8cdf8c; */
	background-image: url(../../../../public/assets/img/BackgImgRed.png);
	color: #818080ba;
}
.schedule-table.schedule .ant-table-cell.day-national-holiday {
	/* background-image: linear-gradient(to right, rgb(0, 87, 183, 30%) 50%, rgb(255, 221, 0, 30%) 50%); */
	background-image: url(../../../../public/assets/img/BackgImgRed.png);
	color: #818080ba;
}
.spec-day-color-lable__not-national,
.spec-day-color-lable__national {
	display: flex;
	align-items: center;
}

.spec-day-color-lable__national::before,
.spec-day-color-lable__not-national::before {
	content: '';
	display: inline-flex;
	width: 35px;
	height: 35px;
	border-radius: 5px;
	margin-right: 6px;
}
.spec-day-color-lable__national::before {
	background-image: linear-gradient(to right, rgb(0, 87, 183, 30%) 50%, rgb(255, 221, 0, 30%) 50%);
}
.spec-day-color-lable__not-national::before {
	background-color: #8cdf8c;
}

.schedule-table.schedule td.ant-table-cell.day-current {
	border-left: 2px solid #008aff;
	border-right: 2px solid #008aff;
	border-top: none;
	border-bottom: none;
}
.schedule-table.schedule th.ant-table-cell.day-current {
	border-left: 2px solid #008aff;
	border-right: 2px solid #008aff;
	border-top: 2px solid #008aff;
	border-bottom: none;
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
	color: #008aff;
}
/* .schedule-table.schedule td.ant-table-cell.day-current::after {
	content: '';
	display: block;
	width: 48px;
	height: 1px;
	background-color: #f0f0f0;
	position: absolute;
	bottom: 0;
} */
.schedule-table.schedule th.ant-table-cell.day-current::after {
	content: '';
	display: block;
	width: 48px;
	height: 1px;
	background-color: #f0f0f0;
	position: absolute;
	bottom: 0;
}
.schedule-table.schedule th.ant-table-cell.day-current span {
	display: flex;
	width: 28px;
	height: 28px;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	border-radius: 50%;
	background-color: #008aff;
	color: #ffff;
	padding: 5px;
}
.schedule-table.schedule td.ant-table-cell.empty-date {
	cursor: pointer;
}

:where(.css-dev-only-do-not-override-1gpfx4k).ant-table-wrapper
	.ant-table-thead
	> tr
	> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
		[colspan]
	)::before,
:where(.css-dev-only-do-not-override-1gpfx4k).ant-table-wrapper
	.ant-table-thead
	> tr
	> td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
		[colspan]
	)::before {
	display: none;
}

.check-mark-schedule {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
}
.schedule-table-column-name {
	display: flex;
	padding: 4px 8px;
	overflow: hidden;
	text-overflow: ellipsis;
}

#components-dropdown-demo-arrow .ant-btn {
	margin-right: 8px;
	margin-bottom: 8px;
}
.ant-row-rtl #components-dropdown-demo-arrow .ant-btn {
	margin-right: 0;
	margin-bottom: 8px;
	margin-left: 8px;
}
.staff-requests-table .ant-table-tbody .ant-table-cell {
	padding: 12px 6px;
}
.staff-requests-action__box {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}
.staff-requests-action__icons {
	font-size: 22px;
	cursor: pointer;
	padding: 4px;
}
.staff-table.departments-table {
	height: calc(100vh - 280px);
}

.departments-modal-tabs .dep-schedule-modal-disabled {
	background-color: #ffff;
}
.departments-modal-tabs .dep-schedule-modal-disabled .ant-picker-input > input {
	color: #152235;
	cursor: default;
}
.departments-modal-tabs
	.dep-schedule-modal
	.dep-schedule-modal-disabled.ant-picker.ant-picker-disabled {
	cursor: default;
}
.departments-modal-tabs .dep-schedule-modal .ant-picker-input > input {
	color: #152235;
	cursor: default;
}
.departments-modal-tabs .dep-schedule-modal .ant-picker.ant-picker-disabled {
	cursor: default;
}

.spec-days-title-cards {
	width: 200px;
	border-radius: 8px;
	box-shadow:
		0 1px 2px -2px rgba(0, 0, 0, 0.16),
		0 3px 6px 0 rgba(0, 0, 0, 0.12),
		0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.spec-days-title-cards__header {
	font-size: 20px;
	font-weight: 500;
	padding: 16px 16px 0px 16px;
}
.spec-days-title-cards__body {
	padding: 8px 16px 16px 16px;
}

.home-page {
	margin: 12px;
	padding: 12px;
	height: calc(100vh - 200px);
	overflow-y: auto;
	display: flex;
	gap: 16px;
}
.home-page-block {
	width: calc(100% / 3);
}
.home-page-item {
	border: 1px solid #f0f0f0;
	border-radius: 6px;
	padding: 8px;
	box-shadow:
		0 1px 2px -2px rgba(0, 0, 0, 0.16),
		0 3px 6px 0 rgba(0, 0, 0, 0.12),
		0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.home-page-item:not(:last-child) {
	margin-bottom: 16px;
}
.home-page-item__title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 56px;
	padding: 12px 12px 16px;
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 8px;
	color: #152235;
	border-bottom: 2px solid #f0f0f0;
}
.home-page-link {
	margin-left: auto;
	color: #1677ff;
	font-size: 18px;
	cursor: pointer;
	padding: 4px;
}

.home-page-item__body {
	display: flex;
	align-items: center;
	padding: 8px 12px;
	border-bottom: 2px solid #f0f0f0;
	border-bottom-left-radius: 22px;
}
.home-page-item__body:not(:last-child) {
	margin-bottom: 10px;
}

.services-responsile-item {
	width: 360px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
	border-bottom: 1px solid #f0f0f0;
	border-bottom-left-radius: 22px;
}

.department-new-blank-category-label {
	margin-left: 12px;
	padding: 8px 12px;
	color: #3d3f3d;
	border-radius: 8px;
	background-color: #c3dff5;
}

.department-new-blank-category-label {
	margin-left: 12px;
	padding: 8px 12px;
	color: #3d3f3d;
	border-radius: 8px;
	background-color: #c3dff5;
}

.ant-table-wrapper.schedule-table.schedule .ant-spin-nested-loading {
	overflow: auto;
}

.content-conteiner.schedule .schedule-table.schedule .ant-table-body {
	padding-bottom: 4px;
}

/* border bottom current day */
.ant-table-row.ant-table-row-level-0:last-child .ant-table-cell.empty-date.day-current.day-current {
	border-bottom: 2px solid #008aff;
	border-bottom-right-radius: 6px;
	border-bottom-left-radius: 6px;
	overflow: hidden;
}
