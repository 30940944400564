.badge {
    padding: 2px 4px;
    border-radius: 4px;

}

.ant-badge-status-dot {
    outline: white solid 2px;
}

.success {
    background: #E8F7F3;
}

.error {
    background: #FBE9EE;
}

.default {
    background: #F5F5F5;
}
