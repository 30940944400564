.info-category-box-action {
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: space-between;
}
.ant-table-tbody .info-category-item {
	padding: 0 !important;
}
.info-category-item__icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 42px;
	height: 42px;
	border-radius: 50%;
	margin: 0 auto;
}
.info-category-select {
	width: 100%;
	margin-bottom: 24px;
}

.category-select-option {
	display: flex;
	gap: 8px;
	align-items: center;
}

.info-category-select svg {
	margin-right: 8px;
}
.category-select-color-box {
	width: 16px;
	height: 16px;
	border-radius: 4px;
}

.directory-type-hover-info {
	margin-left: 5px;
	box-sizing: border-box;
	display: flex;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	border: 2px solid #00000073;
	color: #00000073;
	align-items: center;
	justify-content: center;
	font-size: 10px;
	font-weight: 700;
	cursor: pointer;
}
.directory-type-hover-info:hover + .directory-type-hover-text {
	display: flex;
}
.directory-type-hover-text {
	display: none;
	position: absolute;
	bottom: 5px;
	left: 55px;
	min-height: 50px;
	min-width: 50px;
	border-radius: 8px;
	border: 1px solid #1677ff;
	background-color: #e6f4ff;
	padding: 12px 22px;
	font-style: italic;
	text-align: center;
	align-items: center;
	line-height: 1.4;
}
.modal-delete {
	min-height: 70px;
	margin-top: 20px;
	display: flex;
	align-items: center;
}
