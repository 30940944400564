.content {
  background-color: #f8f9fc;
  width: 100%;
  overflow: hidden;
}
.main-header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-weight: 500;
  min-height: 102px;
  background-color: #ffff;
  gap: 20px;
}
.main-conteiner {
  text-align: center;
  margin: 24px;
  padding: 24px;
  font-size: 18px;
}
.content-header {
  padding: 16px 24px;
  background-color: #ffff;
}
.home-content-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  padding: 16px 24px;
  background-color: #ffff;
}
.home-content-header h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
}
.content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
  font-weight: 500;
  margin: 16px 0 0 0;
  min-height: 32px;
}
.content-title__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 12px;
  align-items: baseline;
}
.content-title__item {
  color: #00000073;
  font-size: 14px;
  line-height: 1.58;
}
.content-title__item:first-child {
  font-size: 20px;
  font-weight: 500;
  color: #000000d9;
  line-height: 1.4;
}
.content-conteiner {
  margin: 24px;
  padding: 12px 24px;
  /* min-height: 100vh; */
  background-color: #ffff;
}

.list-header .ant-list-header {
  padding-bottom: 0;
}

.content-conteiner-list__item {
  height: 46px;

  transition: all 300ms;
}
.content-conteiner-list__item:hover {
  background-color: #fafafa;
}
.content-conteiner-list__item a {
  display: block;
  width: 100%;
  padding: 8px 0;
}

.ant-list-item-meta-title {
  margin: 0 !important;
}
.community-table__link {
  display: block;
  color: #000000e0;
  font-weight: 400;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
}
.community-table__item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.community-table__status {
  display: flex;
  align-items: center;
}
.community-table__status-active {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.community-table__status-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #00000026;
  margin-right: 10px;
}
.community-table__status-circle-active {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #16ad85;
  margin-right: 10px;
}

/***** New styles for admins site****/
.no-data {
  text-align: center;
  margin-top: 150px;
}
.no-data__text {
  color: #00000040;
}
.add-category-btn {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 10px;
}
.add-category-btn.add-advertisements-btn {
  height: 32px;
}

.add-cat-box-label {
  display: flex;
  align-items: center;
  gap: 10px;
}
.info-category-table__item {
  padding: 0 12px !important;
}
.info-category-table__link {
  display: flex;
  width: 100%;
  height: 46px;
  align-items: center;
  color: inherit;
  cursor: pointer;
}

.categ-details-table__photo {
  padding: 10px !important;
}
.categ-details-table__photo div {
  font-size: 10px;
}

.add-form-tabs > .ant-tabs-nav {
  padding: 0 24px;
  background-color: #ffff;
}

.add-form-tabs > .ant-tabs-nav {
  margin-bottom: 0;
}

/***** Dashboard conteiner ******/
.community-dash-conteiner {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  padding: 24px;
  height: calc(100vh - 120px);
  width: 100%;
  overflow-y: scroll;
}
.community-dash-card {
  height: 340px;
  width: calc(100% / 4 - 18px);
  border-radius: 0;
}
.community-dash-card > .ant-card-cover {
  height: 268px;
}
.community-dash-card img {
  height: 100%;
  object-fit: contain;
}

.community-dash-card .ant-card-meta-title {
  text-align: center;
}

.general-dash-direct-type_container {
  height: calc(100vh - 48px);
  overflow-y: scroll;
}
.general-dash-direct-type_box {
  padding: 24px;
}
.general-dash-direct-type_box h2 {
  margin: 0 0 24px 0;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.general-dash-direct-type_cards {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.general-dash-direct-type_card {
  width: calc(100% / 4 - 18px);
  border-radius: 0;
}
.general-dash-direct-type_card > .ant-card-cover {
  height: 230px;
}
.general-dash-direct-type_card > .ant-card-cover img {
  border-radius: 0;
  height: 100%;
  object-fit: cover;
}
.general-dash-direct-type_card > .ant-card-body {
  border-radius: 0;
}

.general-dash-direct-type_card > .ant-card-body {
  border-radius: 0;
}

.card-how-use > .ant-card-body {
  padding: 0px 20px 7px 20px ;
}
